<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" style="min-height: 80vh">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Editar Linea {{ $route.params.id_line }}
            </div>

            <div class="subtitle-1 font-weight-light"></div>
          </template>

          <template>
            <div>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <template v-for="n in steps">
                    <v-stepper-step
                      :key="`${n}-step`"
                      :complete="e1 > n"
                      :step="n"
                      editable
                    >
                      Paso {{ n }}
                    </v-stepper-step>
                    <v-divider v-if="n !== steps" :key="n"></v-divider>
                  </template>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content :step="1">
                    <v-card class="mb-12 pb-4" color="grey lighten-5">
                      <v-card-text>
                        <v-form ref="form" class="px-6" v-model="validStepOne">
                          <v-row>
                            <v-col cols="3">
                              <v-text-field
                                label="Codigo de contrato"
                                v-model="infoLine.contractCode"
                                placeholder="Ej. XG6714"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                label="Codigo de Linea"
                                v-model="infoLine.lineCode"
                                placeholder="Ej. XG67140023"
                                :rules="[rules.required]"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Nombre de linea"
                                v-model="infoLine.lineName"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                label="Codigo de ruta"
                                v-model="infoLine.routeCode"
                                placeholder="Ej. XG6714"
                                :rules="[rules.required]"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                label="Nombre de ruta"
                                v-model="infoLine.routeName"
                                placeholder="Ej. XG67140023"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="3">
                              <v-text-field
                                label="Duración del trayecto"
                                type="number"
                                v-model="infoLine.duration"
                                suffix="Minutos"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                label="Precio"
                                type="number"
                                v-model="infoLine.price"
                                suffix="€"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row justify="center">
                            <v-col cols="2">
                              <v-switch
                                v-model="infoLine.active"
                                label="Linea activa"
                                color="success"
                                hide-details
                              ></v-switch>
                            </v-col>
                            <v-col cols="2">
                              <v-switch
                                v-model="infoLine.allYear"
                                label="Frecuencia: Todo el año"
                                color="success"
                                hide-details
                              ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-dialog
                                v-if="!infoLine.allYear"
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="infoLine.date"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="infoLine.date"
                                    label="Inicio de temporada"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="infoLine.date">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog.save(infoLine.date)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-dialog
                                v-if="!infoLine.allYear"
                                ref="dialog2"
                                v-model="modal1"
                                :return-value.sync="infoLine.date1"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="infoLine.date1"
                                    label="Fin de temporada"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="infoLine.date1">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal1 = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog2.save(infoLine.date1)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                    <v-btn
                      color="primary"
                      :disabled="submitDisabledOne"
                      @click="nextStep(1, 'infoLine', infoLine)"
                    >
                      Continuar
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content :step="2">
                    <v-card class="mb-12 pa-3" color="grey lighten-5">
                      <h2>
                        Por favor introduzca las paradas del recorrido en orden
                      </h2>
                      <v-form ref="form2" v-model="validStepTwo">
                        <v-card v-for="(el, i) in countBusstop" class="pa-3">
                          <h3>Número de parada {{ i + 1 }}</h3>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="ID Parada"
                                placeholder="Ej. 12345"
                                v-model="el.idBusstop"
                                :rules="[rules.required]"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Nombre Parada"
                                placeholder="Ej. Vigo E.I"
                                v-model="el.nameBusstop"
                                :rules="[rules.required]"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Codigo de parada"
                                placeholder="Ej. 36039-42"
                                v-model="el.codeBusstop"
                                :rules="[rules.required]"
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Latitud"
                                type="number"
                                placeholder="Ej. 42.2350319"
                                v-model="el.latBusstop"
                                :rules="[rules.required]"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Longitud"
                                type="number"
                                placeholder="-8.7128182"
                                v-model="el.lonBusstop"
                                :rules="[rules.required]"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Tiempo a la siguiente parada, en minutos"
                                type="number"
                                placeholder="10"
                                v-model="el.timeNextBusstop"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex justify-end">
                              <v-btn
                                class="d-flex align-self-end"
                                color="info"
                                small
                                @click="addBusstop(i)"
                                >Añadir parada</v-btn
                              >
                              <v-btn
                                v-if="countBusstop.length > 1"
                                class="d-flex align-self-end"
                                color="red"
                                small
                                @click="deleteBusstop(i)"
                                >Eliminar parada</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>
                    </v-card>

                    <v-btn
                      color="primary"
                      @click="nextStep(2, 'countBusstop', countBusstop)"
                    >
                      Continue
                    </v-btn>

                    <v-btn text @click="beforeStep(1)"> Atras </v-btn>
                  </v-stepper-content>

                  <v-stepper-content :step="3">
                    <v-card class="mb-12" color="grey lighten-5">
                      <v-form ref="form3" v-model="validStepThree">
                        <v-card v-for="(el, i) in countExpedition">
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Codigo ida"
                                v-model="el.expedition_code_departure"
                                placeholder="XG62104701006"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Hora salida ida"
                                v-model="el.time_departure"
                                type="time"
                                suffix="24h."
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Codigo vuelta"
                                v-model="el.expedition_code_arrival"
                                placeholder="XG62104701006"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Hora salida vuelta"
                                v-model="el.time_arrival"
                                type="time"
                                suffix="24h."
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="px-2">
                            <v-col cols="10">
                              <v-chip
                                v-for="(day, k) in el.week_frequency"
                                :key="k"
                                :color="day.status ? 'primary' : ''"
                                class="ma-2"
                                @click="day.status = !day.status"
                              >
                                {{ day.name }}
                              </v-chip>
                            </v-col>
                            <v-col class="d-flex justify-end">
                              <v-btn
                                class="d-flex align-self-end"
                                color="red"
                                small
                                @click="deleteExpedition(i)"
                                >Eliminar Expedición</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card>
                        <v-row class="d-flex justify-end"
                          ><v-btn color="info" @click="addExpedition"
                            >Añadir expedicion</v-btn
                          ></v-row
                        >
                      </v-form>
                    </v-card>
                    <div>
                      <v-dialog v-model="dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="!validStepThree"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              saveCache('countExpedition', countExpedition)
                            "
                          >
                            Guardar Linea
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            ¿Confirma que todos los datos introducidos son
                            correctos?
                          </v-card-title>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="saveLine()">
                              Confirmar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                    <v-btn text @click="beforeStep(2)"> Atras </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </template>
        </base-material-card>
      </v-col>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import db from "../../../db/example.json";
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      linea: null,
      snackbar: false,
      text: "Linea creada con exito",
      timeout: 2000,
      dialog: false,
      rules: {
        required: (value) => !!value || "Requerido.",
      },
      addLine: false,
      lines: db,
      e1: 1,
      steps: 3,
      menu: false,
      modal: false,
      modal1: false,
      modal2: false,
      infoLine: {
        contractCode: "",
        lineCode: "",
        lineName: "",
        routeName: "",
        routeCode: "",
        duration: "",
        price: "",
        active: false,
        allYear: true,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      modalDepartureTime: false,
      modalArrivalTime: false,
      countBusstop: [],

      countExpedition: [],
      validStepOne: false,
      validStepTwo: false,
      validStepThree: false,
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  async mounted() {
    axios
      .get(
        `https://main-api.apptobus.com/api/get_bus_line_by_line_id/${this.$route.params.id_line}`
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.result);
          this.linea = response.data.result;
          this.infoLine = {
            contractCode: this.linea.contract_code,
            lineCode: this.linea.line_code,
            lineName: this.linea.line_name,
            routeName: this.linea.route_name,
            routeCode: this.linea.route_code,
            duration: this.linea.duration,
            price: this.linea.price,
            active: this.linea.active,
            allYear: true,
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
            date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
          };
        }
        for (let i = 0; i < this.linea.busstops.length; i++) {
          let tiempoTemp = 0;
          if (this.linea.busstops.length - 1 !== i) {
            tiempoTemp = this.restarHoras(
              this.linea.busstops[i + 1].time_departure[0],
              this.linea.busstops[i].time_departure[0]
            );
          }
          let temp = {
            idBusstop: this.linea.busstops[i].bus_stop_id,
            nameBusstop: this.linea.busstops[i].busstop,
            codeBusstop: this.linea.busstops[i].bus_stop_code,
            lonBusstop: this.linea.busstops[i].longitude,
            latBusstop: this.linea.busstops[i].latitude,
            timeNextBusstop: tiempoTemp,
          };
          this.countBusstop.push(temp);
        }
        
        console.log(this.linea.expedition_code_arrival.length)
        for (
          let i = 0;
          i < this.linea.expedition_code_arrival.length;
          i++
        ) {
          console.log(this.linea.week_frequency[i])
          let temp = {
            expedition_code_departure: this.linea.expedition_code_departure[i],
            time_departure: this.linea.busstops[0].time_departure[i],
            expedition_code_arrival: this.linea.expedition_code_arrival[i],
            time_arrival: this.linea.busstops[0].time_arrival[i],
            week_frequency: [
              {
                name: "Lunes",
                value: "L",
                status: this.linea.week_frequency[i].includes("L"),
              },
              {
                name: "Martes",
                value: "M",
                status: this.linea.week_frequency[i].includes("M"),
              },
              {
                name: "Miercoles",
                value: "X",
                status: this.linea.week_frequency[i].includes("X"),
              },
              {
                name: "Jueves",
                value: "J",
                status: this.linea.week_frequency[i].includes("J"),
              },
              {
                name: "Viernes",
                value: "V",
                status: this.linea.week_frequency[i].includes("V"),
              },
              {
                name: "Sabado",
                value: "S",
                status: this.linea.week_frequency[i].includes("S"),
              },
              {
                name: "Domingo",
                value: "D",
                status: this.linea.week_frequency[i].includes("D"),
              },
              {
                name: "Festivos",
                value: "F",
                status: this.linea.week_frequency[i].includes("F"),
              },
            ],
          };
          this.countExpedition.push(temp);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  computed: {
    submitDisabledOne() {
      return !this.validStepOne; // devuelve `true` si el formulario no es válido
    },
  },
  methods: {
    nextStep(n, nameObject, data) {
      // Guardamos en local storage el objeto para seguridad de la experiencia de usuario

      localStorage.setItem(nameObject, JSON.stringify(data));

      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    beforeStep(n) {
      // Guardamos en local storage el objeto para seguridad de la experiencia de usuario
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n;
      }
    },
    addExpedition() {
      this.countExpedition.push({
        expedition_code_departure: "",
        time_departure: "",
        expedition_code_arrival: "",
        time_arrival: "",
        week_frequency: [
          { name: "Lunes", value: "L", status: false },
          { name: "Martes", value: "M", status: false },
          { name: "Miercoles", value: "X", status: false },
          { name: "Jueves", value: "J", status: false },
          { name: "Viernes", value: "V", status: false },
          { name: "Sabado", value: "S", status: false },
          { name: "Domingo", value: "D", status: false },
          { name: "Festivos", value: "F", status: false },
        ],
      });
      this.saveCache("countExpeditionEdit", this.countExpedition);
    },
    restarHoras(hora1, hora2) {
      const formatoHora = "HH:mm"; // Formato de las horas (24 horas)

      // Parsear las horas utilizando moment.js
      const momentHora1 = moment(hora1, formatoHora);
      const momentHora2 = moment(hora2, formatoHora);

      // Restar las horas y obtener la diferencia en minutos
      const diferenciaMinutos = momentHora1.diff(momentHora2, "minutes");

      return diferenciaMinutos;
    },
    addBusstop(i) {
      this.countBusstop.splice(i + 1, 0, {
        idBusstop: "",
        nameBusstop: "",
        codeBusstop: "",
        lonBusstop: "",
        latBusstop: "",
        timeNextBusstop: "",
      });
      this.saveCache("countBusstopEdit", this.countBusstop);
    },
    deleteBusstop(i) {
      this.countBusstop.splice(i, 1);
      this.saveCache("countBusstopEdit", this.countBusstop);
    },
    deleteExpedition(i) {
      this.countExpedition.splice(i, 1);
      this.saveCache("countExpeditionEdit", this.countExpedition);
    },
    saveCache(nameObject, data) {
      localStorage.setItem(nameObject, JSON.stringify(data));
    },
    saveLine() {
      this.dialog = false;
      const busStopsResult = [];
      let acumulator = 0;
      const expeditionCodeDeparture = this.countExpedition.map(
        (val) => val.expedition_code_departure
      );
      const expeditionCodeArrival = this.countExpedition.map(
        (val) => val.expedition_code_arrival
      );
      const weekFrequencyRaw = this.countExpedition.map((val) =>
        val.week_frequency.filter((el) => el.status)
      );
      const weekFrequency = weekFrequencyRaw.map((el) =>
        el.map((el) => el.value).join("")
      );
      const anualFrequency = this.infoLine.allYear
        ? "todo el año"
        : `${this.infoLine.date}, ${this.infoLine.date1}`;
      let timeDeparture = [];
      let timeArrival = [];
      let acumulatorFinal = this.countBusstop.reduce((acumulador, arr) => {
        return acumulador + Number(arr.timeNextBusstop);
      }, 0);
      for (let i = 0; i < this.countBusstop.length; i++) {
        const countBusstop = {
          timeNextBusstop: "",
        };
        if (i === 0) {
          timeDeparture = this.countExpedition.map((el) => el.time_departure);
          timeArrival = this.countExpedition.map((el) =>
            sumarMinutosAHora(el.time_arrival, acumulatorFinal)
          );
        } else {
          acumulator = Number(this.countBusstop[i - 1].timeNextBusstop);
          timeDeparture = timeDeparture.map((el) =>
            sumarMinutosAHora(el, acumulator)
          );
          timeArrival = timeArrival.map((el) =>
            restarMinutosAHora(
              el,
              Number(this.countBusstop[i - 1].timeNextBusstop)
            )
          );
        }
        const busStopDefault = {
          id: null,
          busstop: this.countBusstop[i].nameBusstop,
          time_departure: timeDeparture,
          time_arrival: timeArrival,
          on_demand: 1,
          school_integration: 0,
          ordinal: i + 1,
          bus_stop_id: this.countBusstop[i].idBusstop,
          bus_stop_code: this.countBusstop[i].codeBusstop,
          longitude: this.countBusstop[i].lonBusstop,
          latitude: this.countBusstop[i].latBusstop,
        };
        busStopsResult.push(busStopDefault);
      }
      const resultLine = {
        line_id: null,
        active: this.infoLine.active,
        contract_code: this.infoLine.contractCode,
        line_name: this.infoLine.lineName,
        line_code: this.infoLine.lineCode,
        route_name: this.infoLine.routeName,
        route_code: this.infoLine.routeCode,
        expedition_name: this.infoLine.routeName,
        expedition_code_departure: expeditionCodeDeparture,
        expedition_code_arrival: expeditionCodeArrival,
        week_frequency: weekFrequency,
        anual_frequency: anualFrequency,
        busstops: busStopsResult,
        operator: null,
        operator_id: Number(localStorage.getItem("operator_id")),
        warnings: [],
        special_rates: [],
        price: this.infoLine.price,
        duration: this.infoLine.duration,
        school_integration: 0,
        school_seats: 0,
        emails: null,
        on_demand: 1,
        ondemand_seats: 0,
        reservable_seats: null,
      };
      function sumarMinutosAHora(hora, minutos) {
        const formatoHora = "HH:mm"; // formato de la hora

        // convertir la cadena de caracteres a un objeto moment
        const momentHora = moment(hora, formatoHora);

        // sumar los minutos
        const horaConMinutosSumados = momentHora.add(minutos, "minutes");

        // retornar la hora con los minutos sumados en formato de cadena de caracteres
        return horaConMinutosSumados.format(formatoHora);
      }

      function restarMinutosAHora(hora, minutos) {
        const formatoHora = "HH:mm"; // formato de la hora

        // convertir la cadena de caracteres a un objeto moment
        const momentHora = moment(hora, formatoHora);

        // restar los minutos
        const horaConMinutosRestados = momentHora.subtract(minutos, "minutes");

        // retornar la hora con los minutos restados en formato de cadena de caracteres
        return horaConMinutosRestados.format(formatoHora);
      }
      console.log(JSON.stringify(resultLine));
      axios
        .post("https://main-api.apptobus.com/api/update_bus_line", resultLine)
        .then((response) => {
          if (response.status === 200) {
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
